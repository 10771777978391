import { graphql, useStaticQuery } from 'gatsby';
// biome-ignore lint/style/useImportType: <explanation>
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import type { AllImage, Job } from '../../utils/graphql';
import useElementOnScreen from '../../utils/useElementOnScreen';
import useFilteredContent from '../../utils/useFilteredContent';
import * as Colors from '../Colors';
import TestimonialsCarousel from '../TestimonialsCarousel';
import ApplySection from './ApplySection';
import JobApplication from './JobApplication';
import JobCard from './JobCard';

type JobsProps = {
  jobs: Job[];
};

const JobsContent: React.VoidFunctionComponent<JobsProps> = ({ jobs }) => {
  const {
    allFile: { nodes: teamMemberImages },
  }: {
    allFile: AllImage;
  } = useStaticQuery(
    graphql`
      query {
        allFile(
          filter: { sourceInstanceName: { eq: "images" }, relativeDirectory: { eq: "team" } }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 432
                height: 432
                placeholder: BLURRED
                transformOptions: { cropFocus: CENTER, fit: COVER }
              )
            }
            name
          }
        }
      }
    `,
  );

  const { t } = useTranslation('jobs');

  const { filteredContent, filterComponents } = useFilteredContent(
    jobs,
    ['contracts', 'cities', 'domains'],
    t,
  );

  const [animatedElement, isInView] = useElementOnScreen<HTMLDivElement>();

  return (
    <div className="bg-white text-black md:pt-8">
      <div
        ref={animatedElement}
        className={`container translate-y-12 px-4 opacity-0 sm:px-8 ${
          isInView ? 'animate-slidein' : ''
        }`}
      >
        <h1 className="m-0">{t('header-title')}</h1>
      </div>
      <TestimonialsCarousel
        t={t}
        testimonialsi18nKey="testimonials"
        testimonialsThemes={[
          Colors.WHITE_ON_BLUE,
          Colors.WHITE_ON_BLACK,
          Colors.BLACK_ON_YELLOW,
          Colors.WHITE_ON_BLACK,
          Colors.WHITE_ON_BLUE,
        ]}
        images={teamMemberImages}
      />
      <section className="container my-0 px-4 pt-12 sm:px-8">{filterComponents}</section>
      <section className="container my-0 px-4 sm:px-8 sm:pt-4 md:pt-12" data-testid="results">
        <h2 className="my-4 py-2 text-2xl font-semibold sm:text-3xl md:mb-5 md:mt-0 md:py-3">
          {t('render.n-available-jobs', { count: filteredContent.length })}
        </h2>
        <ul className="m-0 p-0">
          {filteredContent.map((job) => (
            <li key={job.slug}>
              <JobCard job={job} />
            </li>
          ))}
        </ul>
      </section>
      <JobApplication
        ctaButton={t('render.button.spontaneousApplication')}
        ctaTheme={{
          class: Colors.BLUE_ON_WHITE,
          pseudoClass: Colors.BLUE_ON_WHITE_PSEUDO_CLASS,
        }}
        formTitle={t('form.title.spontaneousApplication')}
        emailSubjet={t('email.simpleSubject')}
      >
        <ApplySection />
      </JobApplication>
    </div>
  );
};

export default JobsContent;
