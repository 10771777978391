import * as React from 'react';

type UseSwipeParams = {
  show?: number;
  widthCallback?: (width: number) => void;
  dragCallback?: (transform: number) => void;
  slideCallback: (direction: SlideDirection) => void;
  transform?: number;
  swiping?: boolean;
  swipeOn?: number;
  responsive?: boolean;
  triggerClickOn?: number;
};

export enum SlideDirection {
  Right = -1,
  Left = 1,
}

const useWindowWidthChange = (callBack: (changed: number) => void) => {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useLayoutEffect(() => {
    const update = () => {
      const changed = windowWidth - window.innerWidth;
      setWindowWidth(window.innerWidth);
      callBack(changed);
    };
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [callBack, windowWidth]);
};

function getPageX(e: React.TouchEvent | React.MouseEvent): number {
  if (e.nativeEvent instanceof MouseEvent) {
    return e.nativeEvent.pageX;
  }
  if (e.nativeEvent instanceof TouchEvent) {
    return e.nativeEvent.changedTouches[0].pageX;
  }
  return 0;
}

const useDrag = (
  width: number,
  transform: number,
  swipeOn: number,
  triggerClickOn: number,
  slideCallback: (direction: SlideDirection) => void,
  dragCallback?: (transform: number) => void,
) => {
  const [drag, setDrag] = React.useState({
    initial: transform,
    start: 0,
    isDown: false,
    drag: 0,
    finished: true,
    pointers: true,
  });

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    e.persist();
    setDrag({
      ...drag,
      isDown: true,
      start: getPageX(e),
      initial: transform,
      finished: false,
    });
  };

  const handleDragFinish = (e: React.MouseEvent | React.TouchEvent) => {
    e.persist();
    if (drag.finished) {
      return;
    }
    if (Math.abs(drag.drag) < width * swipeOn) {
      if (dragCallback) dragCallback(transform);
      return setDrag({
        initial: transform,
        start: 0,
        isDown: false,
        drag: 0,
        finished: true,
        pointers: true,
      });
    }

    slideCallback(drag.drag > 0 ? SlideDirection.Right : SlideDirection.Left);
    setDrag({ ...drag, drag: 0, isDown: false, finished: true, pointers: true });
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    e.persist();
    if (!drag.isDown) {
      return;
    }
    const pos = getPageX(e);
    setDrag({
      ...drag,
      drag: drag.start - pos,
      pointers: Math.abs(drag.start - pos) < triggerClickOn,
    });
  };

  return {
    handleDragStart,
    handleDragMove,
    handleDragFinish,
    drag: drag.drag,
    pointers: drag.pointers,
  };
};

const useSwipe = ({
  show = 1,
  widthCallback,
  dragCallback,
  slideCallback,
  transform = 0,
  swiping = true,
  swipeOn = 1,
  responsive = false,
  triggerClickOn = Number.MIN_SAFE_INTEGER,
}: UseSwipeParams) => {
  const [width, setWidth] = React.useState(200);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const ref = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
        const calculated = node.getBoundingClientRect().width / show;
        setWidth(calculated);
        if (widthCallback) widthCallback(calculated);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width],
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  responsive &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useWindowWidthChange((change: number) => {
      setWidth(width - change);
    });

  const { handleDragStart, handleDragMove, handleDragFinish, drag, pointers } = useDrag(
    width,
    transform,
    swipeOn,
    triggerClickOn,
    slideCallback,
    dragCallback,
  );

  const swipeProps = swiping
    ? {
        onTouchCancel: handleDragFinish,
        onTouchEnd: handleDragFinish,
        onTouchMove: handleDragMove,
        onTouchStart: handleDragStart,
        onMouseDown: handleDragStart,
        onMouseLeave: handleDragFinish,
        onMouseUp: handleDragFinish,
        onMouseMove: handleDragMove,
      }
    : {};

  return { ref, swipeProps, width, drag, pointers };
};

export default useSwipe;
