import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import type { TFunction } from 'react-i18next';
import type { GatsbyImageFileWithName } from '../utils/graphql';
import useSwipe, { type SlideDirection } from '../utils/useSwipe';

export type TestimonyJson = {
  text: string;
  author?: string;
  imageName?: string;
  buttonName: string;
};

type Testimony = TestimonyJson & {
  id: string;
  image?: GatsbyImageFileWithName;
};

const CAROUSEL_CYCLE_FREQUENCY = 1750;

const ANIMATION_DURATION = 'duration-1000';

type TestimonialsCarouselProps = {
  t: TFunction;
  testimonialsi18nKey: string;
  testimonialsThemes: string[];
  images?: GatsbyImageFileWithName[];
};

const TestimonialsCarousel: React.VoidFunctionComponent<TestimonialsCarouselProps> = ({
  t,
  testimonialsi18nKey,
  testimonialsThemes,
  images = [],
}) => {
  const testimonialsContent = React.useMemo(() => {
    const result: Testimony[] = [];
    const testimonials = t<string, { [key: string]: Testimony }>(testimonialsi18nKey, {
      returnObjects: true,
      defaultValue: {},
    });
    for (const key in testimonials) {
      if (Object.prototype.hasOwnProperty.call(testimonials, key)) {
        const testimony = testimonials[key];
        testimony.id = key;
        testimony.image = images.find((img) => img.name === testimony.imageName);
        result.push(testimony);
      }
    }
    return result;
  }, [t, testimonialsi18nKey, images]);

  const [testimonyIndexes, setTestimonyIndexes] = React.useState([0, 0, 0]);

  const carouselTimer = React.useRef<NodeJS.Timer>();

  const cycleCarousel = React.useCallback(() => {
    const [current, next, displayed] = testimonyIndexes;

    if (current === next) {
      setTestimonyIndexes([current, next + 1, displayed]);
    } else if (displayed === current) {
      setTestimonyIndexes([current, next, next]);
    } else {
      setTestimonyIndexes([next, next, displayed]);
    }
  }, [testimonyIndexes]);

  const [currentIndex, nextIndex, displayedIndex] = testimonyIndexes;

  const slideCallback = (direction: SlideDirection) => {
    const newIndex = displayedIndex - direction;
    const validIndex = newIndex < 0 ? testimonialsContent.length + newIndex : newIndex;
    setTestimonyIndexes([validIndex, validIndex, validIndex]);
  };

  const { ref, swipeProps, pointers } = useSwipe({
    swipeOn: 0.25,
    slideCallback,
    responsive: typeof window !== 'undefined',
  });

  React.useLayoutEffect(() => {
    if (pointers) {
      carouselTimer.current = setTimeout(cycleCarousel, CAROUSEL_CYCLE_FREQUENCY);
    }

    return () => {
      if (carouselTimer.current) {
        clearTimeout(carouselTimer.current);
        carouselTimer.current = undefined;
      }
    };
  }, [cycleCarousel, pointers]);

  const currentTestimony = testimonialsContent[currentIndex % testimonialsContent.length];
  const displayedTestimony = testimonialsContent[displayedIndex % testimonialsContent.length];
  const displayedTestimonyTheme = testimonialsThemes[displayedIndex % testimonialsThemes.length];

  const [firstImageIndex, secondImageIndex] =
    currentIndex % 2 === 0
      ? [currentIndex % testimonialsContent.length, nextIndex % testimonialsContent.length]
      : [nextIndex % testimonialsContent.length, currentIndex % testimonialsContent.length];
  const [{ image: firstImage, author: firstAuthor }, { image: secondImage, author: secondAuthor }] =
    [testimonialsContent[firstImageIndex], testimonialsContent[secondImageIndex]];

  const textClassName =
    (displayedIndex % testimonialsContent.length) % 2 === 0
      ? 'left-0 right-32 sm:right-64 md:right-80 lg:right-96'
      : 'right-0 left-32 sm:left-64 md:left-80 lg:left-96';
  const imageClassName = `w-32 sm:w-64 md:w-80 lg:w-96 ${
    (displayedIndex % testimonialsContent.length) % 2 === 0
      ? 'left-full'
      : 'left-32 sm:left-64 md:left-80 lg:left-96'
  }`;

  return (
    <section className="relative w-full pt-12" ref={ref}>
      <div
        className={`relative h-64 transition-colors md:h-80 lg:h-96 ${ANIMATION_DURATION} ${displayedTestimonyTheme}`}
        {...swipeProps}
        style={{ cursor: pointers ? 'auto' : 'grab' }}
      >
        <div
          className={`absolute flex h-full select-none flex-col justify-center p-4 lg:p-10 ${ANIMATION_DURATION} ${
            displayedTestimony.image && !currentTestimony.image
              ? 'transition-colors'
              : 'transition-all'
          } ${displayedTestimony.image ? textClassName : 'left-0 right-0 text-center'}`}
        >
          <div className="whitespace-pre-wrap text-base font-semibold leading-snug md:text-2xl md:font-bold md:leading-snug lg:text-3xl lg:leading-snug xl:text-4xl">
            {displayedTestimony.text}
          </div>
          {displayedTestimony.author && (
            <div className="mt-5 whitespace-pre-wrap text-xs font-medium md:text-base">
              {displayedTestimony.author}
            </div>
          )}
        </div>
        <div
          className={`absolute -ml-32 h-full select-none transition-all sm:-ml-64 md:-ml-80 lg:-ml-96 ${ANIMATION_DURATION} ${
            displayedTestimony.image ? imageClassName : 'left-full w-0'
          }`}
          draggable={false}
        >
          <div
            className={`absolute left-0 top-14 h-32 w-32 select-none transition-opacity sm:top-0 sm:h-64 sm:w-64 md:h-80 md:w-80 lg:h-96 lg:w-96 ${ANIMATION_DURATION} ${
              displayedIndex % 2 === 0 ? 'opacity-100' : 'opacity-0'
            }`}
            draggable={false}
          >
            {firstImage && (
              <GatsbyImage
                image={firstImage.childImageSharp.gatsbyImageData}
                className="h-32 w-32 select-none sm:h-64 sm:w-64 md:h-80 md:w-80 lg:h-96 lg:w-96"
                title={firstAuthor}
                alt={firstAuthor ?? ''}
                draggable={false}
              />
            )}
          </div>
          <div
            className={`absolute left-0 top-14 h-32 w-32 select-none transition-opacity duration-[2s] sm:top-0 sm:h-64 sm:w-64 md:h-80 md:w-80 lg:h-96 lg:w-96 ${
              displayedIndex % 2 === 0 ? 'opacity-0' : 'opacity-100'
            }`}
            draggable={false}
          >
            {secondImage && (
              <GatsbyImage
                image={secondImage.childImageSharp.gatsbyImageData}
                className="h-32 w-32 select-none sm:h-64 sm:w-64 md:h-80 md:w-80 lg:h-96 lg:w-96"
                title={secondAuthor}
                alt={secondAuthor ?? ''}
                draggable={false}
              />
            )}
          </div>
        </div>
        <div className="absolute bottom-1 flex w-full justify-center text-xs sm:bottom-2 sm:text-base md:bottom-4">
          <ul>
            {testimonialsContent.map((testimony, index) => (
              <li key={testimony.id} className="inline-block align-middle">
                <button
                  type="button"
                  className="h-7 align-middle leading-7 sm:h-8 sm:leading-8"
                  title={testimony.buttonName}
                  onClick={() => {
                    if (carouselTimer.current) {
                      clearTimeout(carouselTimer.current);
                      carouselTimer.current = undefined;
                    }
                    setTestimonyIndexes([index, index, index]);
                  }}
                >
                  <span
                    className={`m-2 inline-block h-3 w-3 rounded-full border-2 border-solid border-white transition-colors sm:h-4 sm:w-4 ${ANIMATION_DURATION} ${
                      index === displayedIndex % testimonialsContent.length
                        ? 'bg-reacteev-blue'
                        : 'bg-reacteev-yellow'
                    }`}
                  />
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsCarousel;
